.task-wrapper {
  margin: 0 0 5px 0;
  display: grid;
  grid-template-columns: 3fr auto;
  width: 90%;
  border: solid 2px $black;
  border-radius: 5px;
  box-shadow: -2px 2px 10px $off-white;
  background-color: $off-white;

  .task-render {
    @include flex-center();
    text-align: center;
    p {
      font-family: $source;
      letter-spacing: 0.7px;
      font-size: 1.1em;
      font-weight: 450;
      margin: 5px;
      text-shadow: 0 0 0.5px $teal;
      color: $black;
    }
  }

  .task-action-btns-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    border-left: solid 2px $black;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    .edit-task-btn {
      @include btn();
      border-radius: 0;
      margin: 0;
      padding: 5px;
      background-color: $grey;
      border: none;
      color: $dark-teal;
    }

    .delete-task-btn {
      @include btn();
      border-radius: 0;
      margin: 0;
      padding: 5px;
      background-color: $grey;
      border: none;
      color: red;
    }

    .complete-task-btn {
      color: $black;
      height: 100%;
      width: 100%;
      background-color: green;
      border: 0;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      cursor: pointer;
    }
  }
}
