body {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

#root {
  height: 100%;
  width: 100%;
}

.app-container {
  background: fixed linear-gradient($grey, $off-white);
  margin: 0px;
  height: 100%;

  .app {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    min-height: 100vh;
  }
}

.login-btn {
  @include btn();
}

.register-btn {
  @include btn();
  background-color: $dark-teal;
}
