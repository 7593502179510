.footer-wrapper {
  @include flex-center();
  text-align: center;
  font-family: $poppins;
  font-size: 1.2em;
  height: 50px;
  clear: both;
  padding: 10px;
  background-color: $black;
  color: $teal;
  margin-top: 10px;

  span {
    padding: 5px;
  }
}
