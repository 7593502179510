$black: #0b0c10;
$grey: #1f2833;
$off-white: #c5c6c7;
$teal: #66fcf1;
$dark-teal: #45a29e;
$orange: #964734;
$source: 'Source Sans 3', sans-serif;
$poppins: 'Poppins', sans-serif;

@mixin btn {
  font-family: $source;
  font-size: 1.1em;
  letter-spacing: 0.7px;
  font-weight: 550;
  text-decoration: none;
  color: $black;
  background-color: $teal;
  padding: 2px 10px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin auth-input {
  margin-bottom: 10px;
  padding: 10px 20px;
  font-family: $source;
  font-size: 1.2em;
  font-weight: 500;
  color: $black;
  background-color: $off-white;
  border: solid 2px $teal;
  border-radius: 5px;
  box-shadow: 0 0 5px $black;
}

@mixin form-border {
  border-top: solid 5px $teal;
  border-bottom: solid 5px $teal;
  border-radius: 1rem;
}

@mixin form-heading {
  font-family: $poppins;
  font-size: 1.3em;
  font-weight: 500;
  color: $grey;
  background-color: $off-white;
  padding: 5px 10px;
  border: 2px solid $teal;
  border-radius: 0.5em;
}

@mixin modal-heading {
  font-family: $poppins;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: $black;
  text-shadow: 0 0 5px $teal;
  margin: 0;
}

@mixin task-list-heading {
  text-align: center;
  margin: 10px 0px;
  padding: 5px 10px;
  letter-spacing: 3px;
  font-family: $poppins;
  color: $off-white;
  font-weight: 500;
  text-shadow: -2px 2px 5px $black;
  box-shadow: -2px 2px 10px $teal;
  border-radius: 5px;
  text-transform: uppercase;
}
