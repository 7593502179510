.navbar-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  text-align: center;
  margin-bottom: 10px;
  border-bottom: 3px solid $teal;
  border-radius: 0.3em;
  clear: both;

  .navbar-left {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: $off-white;
      font-family: $poppins;
      font-weight: 500;
      font-size: 1.2em;
      letter-spacing: 2px;
      text-shadow: -1px 1px 2px $black;
    }

    .material-symbols-outlined {
      font-variation-settings: 'FILL' 0, 'wght' 800, 'GRAD' 100, 'opsz' 48;
      font-size: 1.5em;
      color: $teal;
      padding: 5px;
    }
  }

  .navbar-middle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px;

    a {
      color: $teal;
      text-decoration: none;
      border-bottom: 1px transparent;
      font-family: $poppins;
      font-weight: 600;
      letter-spacing: 1.2px;
    }

    .active {
      color: $dark-teal;
      border-bottom: 1px solid $off-white;
      border-radius: 0.25em;
      letter-spacing: 2px;
    }
  }

  .navbar-right {
    font-family: $source;
    @include flex-column();
    color: $off-white;

    .unauthed-user {
      height: 100%;
      @include flex-column();

      .welcome-text {
        margin-bottom: 10px;
        font-family: $source;
        text-shadow: -1px 1px 5px $black;
        letter-spacing: 1.2px;
        font-size: 1.1em;
      }
    }

    .authed-user {
      @include flex-column();

      .welcome-text {
        font-family: $source;
        letter-spacing: 1.2px;
        font-size: 1.1em;
        text-shadow: -1px 1px 5px $black;
        margin: 0 10px;
      }
    }

    .logout-btn {
      @include btn();
      background-color: inherit;
      border: none;
      color: red;
      padding: 10px;
    }

    .edit-user-btn {
      @include btn();
      background-color: inherit;
      border: none;
      color: $dark-teal;
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar-container {
    display: flex;
    flex-direction: column;
    height: 200px;
    grid-template-columns: none;

    .navbar-right {
      @include flex-center();

      .unauthed-user {
        padding: 10px;
      }

      .authed-user {
        flex-direction: row;
        padding: 10px;
      }
    }

    .navbar-middle {
      padding: 10px;
    }
  }
}
